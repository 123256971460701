module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"linkImagesToOriginal":false,"maxWidth":1000,"showCaptions":["alt"],"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":"<svg aria-hidden=\"true\" width=\"8\" height=\"8\" version=\"1.1\" viewBox=\"0 0 8 8\"><path d=\"M0.408 3.156L0.624 1.932H2.052L2.4 0H3.624L3.276 1.932H4.452L4.8 0H6.024L5.676 1.932H7.104L6.888 3.156H5.46L5.244 4.332H6.684L6.468 5.556H5.028L4.692 7.488H3.468L3.804 5.556H2.628L2.292 7.488H1.068L1.404 5.556H0L0.216 4.332H1.62L1.836 3.156H0.408ZM3.06 3.156L2.844 4.332H4.02L4.236 3.156H3.06Z\" fill-rule=\"evenodd\"/></path></svg>","className":"heading-anchor","removeAccents":true,"offsetY":0},
    },{
      plugin: require('../node_modules/gatsby-remark-code-buttons/gatsby-browser.js'),
      options: {"plugins":[],"buttonText":"COPY","toasterText":"COPIED"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gatsby Starter Blog","short_name":"GatsbyJS","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4d09dce2aeb116053cb96891bd9a1fcd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
